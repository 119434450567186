import { useState } from 'react';

import useReCAPTCHARef from '../useReCAPTCHARef';

const useReCAPTCHA = () => {
  const ref = useReCAPTCHARef();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const submit = async () => {
    if (!ref.current) {
      setError('ReCAPTCHA nao inicializado, tente novamente');
      return '';
    }

    setIsLoading(true);

    try {
      return await ref.current.executeAsync();
    } catch (e) {
      setError('Erro ao validar ReCAPTCHA');
      return '';
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, ref, submit } as const;
};

export default useReCAPTCHA;
