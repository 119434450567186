import { captureException, captureMessage } from '@sentry/react';
import { lazy } from 'react';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import { FormLayout } from '../shared/Components';
import { PartnerProvider } from '../shared/Context/Partner';
import { RouteManagerProvider } from '../shared/Context/RouteManager';
import Routes from './routes';

const PolicyHolderForm = lazy(() => import('../pages/PolicyHolderForm'));
const ValidationPF = lazy(() => import('../pages/ValidationPF'));
const ValidationPJ = lazy(() => import('../pages/ValidationPJ'));
const InsuranceAgent = lazy(() => import('../pages/InsuranceAgent'));
const BankData = lazy(() => import('../pages/BankData'));
const FormConclusion = lazy(() => import('../pages/Conclusion'));

const queryClient = new QueryClient();

setLogger({
  log: (message) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      console.log(message);
    }

    captureMessage(message);
  },
  warn: (message) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      console.warn(message);
    }

    captureMessage(message);
  },
  error: (error) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      console.error(error);
    }

    captureException(error);
  },
});

const Router = (): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Switch>
        <PartnerProvider>
          <RouteManagerProvider>
            <FormLayout path={Routes.POLICYHOLDER} currentStep={0}>
              <PolicyHolderForm />
            </FormLayout>
            <FormLayout path={Routes.VALIDATION_PF} currentStep={1}>
              <ValidationPF />
            </FormLayout>
            <FormLayout path={Routes.VALIDATION_PJ} currentStep={1}>
              <ValidationPJ />
            </FormLayout>
            <FormLayout path={Routes.INSURANCE_AGENT} currentStep={2}>
              <InsuranceAgent />
            </FormLayout>
            <FormLayout path={Routes.BANK_DATA} currentStep={3}>
              <BankData />
            </FormLayout>
            <FormLayout path={Routes.CONCLUSION} currentStep={4}>
              <FormConclusion />
            </FormLayout>
            <Route exact path={Routes.INITIAL}>
              <Home />
            </Route>
          </RouteManagerProvider>
        </PartnerProvider>
      </Switch>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default Router;
