import { forwardRef } from 'react';

import { CloseIcon } from '../../Images/Icons';
import * as S from './styles';

interface ModalCloseButtonProps {
  onClose: () => void;
}

const ModalCloseButton = forwardRef<
  HTMLButtonElement,
  ModalCloseButtonProps & GTMButtonData
>(({ children, onClose, ...props }, ref) => {
  return (
    <S.ModalCloseButton onClick={onClose} {...props} ref={ref}>
      <CloseIcon />
    </S.ModalCloseButton>
  );
});

ModalCloseButton.displayName = 'ModalCloseButton';

export default ModalCloseButton;
