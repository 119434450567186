import { FC } from 'react';

import * as S from './styles';

export enum FlagType {
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
}

export interface FlagProps {
  type: FlagType;
}

const Flag: FC<FlagProps> = ({ children, type }) => {
  return (
    <S.Flag $type={type}>
      <S.FlagDecoration $type={type} />
      <S.FlagText $type={type}>{children}</S.FlagText>
    </S.Flag>
  );
};

export default Flag;
