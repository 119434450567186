import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const ModalContentDesktop = css`
  margin: 32px 0;
  padding: 32px;
  border-radius: 32px;
  height: auto;
  width: auto;
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 20;
  padding: 16px 16px 64px;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;

  ${media.greaterThan('medium')`${ModalContentDesktop}`}
`;

export default ModalContent;
