import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import GradientLogo from '../../../../shared/Images/Logos/GradientLogo';

const BackgroundLogoDesktop = css`
  --size: 80vh;
  --offset: calc(var(--size) / 2);

  top: calc(50% - var(--offset));
  left: calc(50% - var(--offset));

  height: var(--size);
`;

const BackgroundLogo = styled(GradientLogo)`
  position: absolute;

  --size: 80vw;
  --offset: calc(var(--size) / 2);

  top: calc(50% - var(--offset));
  left: calc(50% - var(--offset));

  width: var(--size);

  ${media.greaterThan('medium')`${BackgroundLogoDesktop}`}
`;

export default BackgroundLogo;
