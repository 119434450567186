import { FC, ReactElement } from 'react';

import * as S from './styles';

const ErrorLabel: FC = ({ children }): ReactElement => {
  if (!children) {
    return <></>;
  }

  return <S.ErrorLabel>{children}</S.ErrorLabel>;
};

export default ErrorLabel;
