import { fetcher } from '../../Services';
import { APIPartner, CreateLeadData, LeadType, UpdateLeadData } from './types';

export const postLead = async ({ lead, recaptchaToken }: CreateLeadData) => {
  return fetcher<LeadType, GenericRecord>('/leads', {
    body: {
      attributes: lead,
    },
    headers: { 'x-recaptcha-token': recaptchaToken },
  });
};

export const patchLead = ({
  application,
  id,
  lead,
  recaptchaToken,
}: UpdateLeadData) =>
  fetcher<LeadType, GenericRecord>(`/leads/${id}`, {
    body: {
      application,
      attributes: lead,
      id,
    },
    method: 'PATCH',
    headers: { 'x-recaptcha-token': recaptchaToken },
  });

interface PostPartnerType {
  partner: APIPartner;
  recaptchaToken: string;
}

export const postPartner = ({ partner, recaptchaToken }: PostPartnerType) =>
  fetcher<APIPartner, GenericRecord, APIPartner>('/partners', {
    body: { ...partner, phone: `+55${partner.phone}` },
    headers: { 'x-recaptcha-token': recaptchaToken },
  });
