import FocusTrap from 'focus-trap-react';
import React, { FC, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useKey, useLockBodyScroll, useMedia } from 'react-use';
import { defaultBreakpoints } from 'styled-media-query';

import * as S from './styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const modalRootNode = document.getElementById('modal-root');

if (!modalRootNode) {
  throw new Error('Modal root not found');
}

const Modal: FC<ModalProps> = ({ isOpen, children, onClose }) => {
  const isDesktop = useMedia(`(min-width: ${defaultBreakpoints.medium})`);

  const onEscape = useCallback(() => {
    if (isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);

  useKey('Escape', onEscape);

  useLockBodyScroll(isDesktop && isOpen);

  const handleModalStyles = useCallback(() => {
    const root = document.getElementById('root');

    if (!root) {
      return;
    }

    if (isDesktop) {
      root.style.display = '';
      return;
    }

    root.style.display = isOpen ? 'none' : '';
  }, [isDesktop, isOpen]);

  useEffect(() => {
    handleModalStyles();
  }, [handleModalStyles]);

  if (!isOpen) {
    return <></>;
  }

  const modalNode = (
    <FocusTrap>
      <S.Modal id='modal'>{children}</S.Modal>
    </FocusTrap>
  );

  return createPortal(modalNode, modalRootNode);
};

export default Modal;
