import { useField } from 'formik';
import { FC } from 'react';

import Select, { SelectProps } from '../../Select';

interface FormSelectProps extends SelectProps {
  name: string;
}

const FormSelect: FC<FormSelectProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <Select
      {...field}
      {...props}
      error={meta.touched ? meta.error || '' : ''}
    />
  );
};

export default FormSelect;
