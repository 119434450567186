import Downshift from 'downshift';
import { FastField } from 'formik';
import { FastFieldProps } from 'formik/dist/FastField';
import Fuse from 'fuse.js';
import { useMemo, useRef } from 'react';

import ErrorLabel from '../../ErrorLabel';
import { AutocompleteInput, InputContainer } from '../../Input/styles';
import { InputProps } from '../../Input/types';
import InputLabel from '../../InputLabel';
import * as S from './styles';

interface AutocompleteOption {
  name: string;
  value: string;
}

interface AutocompleteProps
  extends Omit<InputProps, keyof GTMInputData>,
    Omit<GTMSelectData, 'data-gtm-type' | 'data-gtm-subname'> {
  name: string;
  options: AutocompleteOption[];
}

const Autocomplete = ({ name, options, ...props }: AutocompleteProps) => {
  const ref = useRef(null);

  const defaultResult: { item: AutocompleteOption }[] = useMemo(() => {
    return options.map((option) => ({ item: option }));
  }, [options]);

  const fuse = useMemo(
    () => new Fuse(options, { keys: ['name', 'value'], minMatchCharLength: 2 }),
    [options],
  );

  return (
    <FastField name={name}>
      {({ form, meta, field }: FastFieldProps) => (
        <S.Container>
          <Downshift<AutocompleteOption>
            onChange={(selection) => {
              if (!selection) {
                return;
              }

              form.setFieldValue(name, selection.value);
            }}
            itemToString={(item) => item?.value ?? ''}
          >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              getLabelProps,
              inputValue,
              highlightedIndex,
              selectedItem,
              isOpen,
            }) => {
              const { as, ...inputProps } = getInputProps({
                onBlur: (ev: unknown) => {
                  field.onBlur(ev);
                },
                ref,
              });

              return (
                <div>
                  <InputContainer>
                    <InputLabel htmlFor={props.id} {...getLabelProps()}>
                      {props.label}
                      {!props.optional ? ' *' : ''}
                    </InputLabel>
                    <AutocompleteInput
                      autoComplete='off'
                      $error={!!meta.error}
                      disabled={props.disabled}
                      id={props.id}
                      name={name}
                      data-gtm-type='select'
                      data-gtm-subname={inputValue ?? ''}
                      {...props}
                      {...inputProps}
                    />
                    <ErrorLabel>
                      {meta.touched ? meta.error : undefined}
                    </ErrorLabel>
                  </InputContainer>
                  <S.Options {...getMenuProps()} isOpen={isOpen}>
                    {(inputValue ? fuse.search(inputValue) : defaultResult).map(
                      ({ item }, index) => (
                        <S.Option
                          key={`${item.value}${index}`}
                          isHighlighted={highlightedIndex === index}
                          {...getItemProps({
                            item,
                            index,
                            isSelected: selectedItem === item,
                          })}
                        >
                          {item.value}
                        </S.Option>
                      ),
                    )}
                  </S.Options>
                </div>
              );
            }}
          </Downshift>
        </S.Container>
      )}
    </FastField>
  );
};

export default Autocomplete;
