import { useField } from 'formik';

import ErrorLabel from '../../ErrorLabel';
import * as S from './styles';

export interface RadioProps extends Omit<GTMSelectData, 'data-gtm-type'> {
  name: string;
  label: string;
  disabled?: boolean;
  value: string;
}

const Radio = ({
  name,
  label,
  disabled = false,
  value,
  'data-gtm-name': dataGTMName,
  'data-gtm-subname': dataGTMSubname,
}: RadioProps) => {
  const [field, meta] = useField(name);

  return (
    <S.Container>
      <S.RadioContainer>
        <S.Input
          {...field}
          checked={field.value === value}
          disabled={disabled}
          value={value}
          type='radio'
          data-gtm-type='select'
          data-gtm-name={dataGTMName}
          data-gtm-subname={dataGTMSubname}
        />
        <S.Label>{label}</S.Label>
      </S.RadioContainer>
      <ErrorLabel>{meta.error}</ErrorLabel>
    </S.Container>
  );
};

export default Radio;
