import React, { ReactElement } from 'react';

import { StyledSvg } from './styles';

const Candle = (): ReactElement => {
  return (
    <StyledSvg
      viewBox='0 0 150 768'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.01813 780.571C14.7801 746.408 61.1538 600.04 77.4521 539.325C87.0616 503.526 93.5882 477.446 95.2599 468.163C96.1855 463.023 98.4384 452.349 100.266 444.442C109.661 403.806 120.377 333.48 124.912 282.711C126.501 264.92 128.461 231.28 129.267 207.955C130.073 184.63 131.397 150.342 132.208 131.761C134.829 71.6919 133.603 13.1053 129.313 -6.60895L128.14 -12H138.4H148.66L149.6 9.9236C151.506 54.3483 146.107 172.611 139.745 225.806C128.984 315.784 117.255 390.056 101.7 466.725C97.725 486.317 96.68 491.73 93.7911 507.697C90.7122 524.714 80.4494 569.753 71.188 606.893C61.1451 647.166 43.3108 701.953 19.0095 767.186L13.3862 782.281L6.67803 782.727C0.679868 783.126 0.0808063 782.898 1.01813 780.571Z'
        fill='#A8DFFB'
      />
    </StyledSvg>
  );
};

export default Candle;
