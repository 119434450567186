import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const DesktopSubtitle = css`
  font-size: 1.5rem;
  text-align: left;
`;

const Subtitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 18px;
  opacity: 0.8;
  color: white;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  margin: 0;
  text-align: center;

  ${media.greaterThan('medium')`${DesktopSubtitle}`}
`;

export default Subtitle;
