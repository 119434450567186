import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #edf3f5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.01);

  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 12px;
  position: relative;
`;
