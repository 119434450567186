import styled from 'styled-components';

const ContentContainer = styled.div`
  --padding-size: 32px;

  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--padding-size);
`;

export default ContentContainer;
