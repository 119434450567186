import { setContext } from '@sentry/react';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Routes from '../../../router/routes';

interface RouteManagerContextType {
  currentRoute: Routes;
  setCurrentRoute: (route: Routes) => void;
  navigateToRoute: (route: Routes) => void;
  resetNavigation: () => void;
}

const RouteManagerContext = createContext<RouteManagerContextType>({
  currentRoute: Routes.INITIAL,
  setCurrentRoute: () => undefined,
  navigateToRoute: () => undefined,
  resetNavigation: () => undefined,
});

export const RouteManagerProvider: FC = ({ children }) => {
  const { push } = useHistory();
  const [currentRoute, setCurrentRoute] = useState<Routes>(Routes.INITIAL);

  const location = useLocation();

  useEffect(() => {
    setContext('route', { location });
  }, [location]);

  useEffect(() => {
    if (location.pathname !== currentRoute) {
      push(currentRoute);
    }
  }, [location, push, currentRoute]);

  const navigateToRoute = (route: Routes) => {
    setCurrentRoute(route);
    push(route);
  };

  const resetNavigation = () => {
    navigateToRoute(Routes.INITIAL);
  };

  return (
    <RouteManagerContext.Provider
      value={{
        setCurrentRoute,
        currentRoute,
        navigateToRoute,
        resetNavigation,
      }}
    >
      {children}
    </RouteManagerContext.Provider>
  );
};

export const useRouteManager = () => useContext(RouteManagerContext);
