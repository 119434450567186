import styled from 'styled-components';

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.grayDark};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  margin-left: 8px;
  margin-bottom: 4px;
`;
