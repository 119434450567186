enum Routes {
  INITIAL = '/',
  POLICYHOLDER = '/dados-estipulante',
  VALIDATION_PJ = '/validacao-pj',
  VALIDATION_PF = '/validacao-pf',
  INSURANCE_AGENT = '/dados-corretor',
  BANK_DATA = '/dados-bancarios',
  CONCLUSION = '/conclusao',
}

export default Routes;
