import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Select = styled.select<{ error?: string }>`
  all: unset;

  width: 100%;
  padding: 12px 36px 12px 18px;

  display: flex;
  align-items: center;

  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #dcf0f8;

  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.grayDark};

  &::placeholder {
    color: ${({ theme }) => theme.colors.grayLighten};
    opacity: 1;
  }

  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => rgba(theme.colors.primary, 0.12)};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:invalid {
    color: ${({ theme }) => theme.colors.grayLighten};
  }

  > * {
    color: ${({ theme }) => theme.colors.grayDark};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${rgba(theme.colors.danger, 0.5)};
      background-color: ${rgba(theme.colors.danger, 0.02)};

      &:focus {
        border-color: ${theme.colors.danger};
        background-color: ${rgba(theme.colors.danger, 0.03)};
        box-shadow: 0 0 0 3px ${(props) => rgba(theme.colors.danger, 0.1)};
      }
    `}
`;

export const SelectContainer = styled.div`
  --size: 6px;
  --offset: 16px;
  --color: ${({ theme }) => theme.colors.grayLight};

  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: var(--size);
    height: var(--size);
    right: 16px;
    transform: rotate(-45deg);
  }

  &::after {
    border-left: 2px solid var(--color);
    border-bottom: 2px solid var(--color);
    bottom: var(--offset);
  }
  &::before {
    border-right: 2px solid var(--color);
    border-top: 2px solid var(--color);
    top: var(--offset);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
