import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    margin-top: 8px;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Input = styled.input`
  appearance: none;

  margin-right: 8px;
  padding: 8px;

  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 14px;

  display: inline-block;
  position: relative;
  cursor: pointer;

  &:checked:after {
    content: '';

    left: 3px;
    top: 3px;

    width: 10px;
    height: 10px;
    border-radius: 20px;

    position: absolute;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => rgba(theme.colors.primary, 0.12)};
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 14px;
  line-height: 140%;

  ${Input}:checked ~ & {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
