import { ReactElement } from 'react';

import ErrorLabel from '../ErrorLabel';
import InputLabel from '../InputLabel';
import * as S from './styles';
import { InputProps } from './types';

const Input = ({
  beforeMaskedStateChange,
  disabled,
  error,
  id,
  label,
  mask,
  name = id,
  onChange,
  optional,
  ...props
}: Omit<InputProps, 'data-gtm-form'>): ReactElement => {
  const customOnChange = !disabled ? onChange : () => void 0;

  return (
    <S.InputContainer>
      <InputLabel htmlFor={id}>
        {label}
        {!optional ? ' *' : ''}
      </InputLabel>
      {mask ? (
        <S.InputMask
          autoComplete='off'
          $error={!!error}
          beforeMaskedStateChange={beforeMaskedStateChange}
          disabled={disabled}
          id={id}
          mask={mask}
          maskPlaceholder={null}
          name={name}
          onChange={customOnChange}
          data-gtm-form='input'
          {...props}
        />
      ) : (
        <S.Input
          autoComplete='off'
          $error={!!error}
          disabled={disabled}
          id={id}
          name={name}
          onChange={customOnChange}
          data-gtm-form='input'
          {...props}
        />
      )}
      <ErrorLabel>{error}</ErrorLabel>
    </S.InputContainer>
  );
};

export default Input;
