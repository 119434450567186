import { setExtra } from '@sentry/react';

class APIError extends Error {
  body: GenericRecord;
  raw: string;

  constructor(message: string, body?: GenericRecord, raw?: string) {
    super(message);
    setExtra('error.message', message);
    this.body = body || {};
    setExtra('error.body', body || {});

    const rawError = raw ?? JSON.stringify(this.body);
    this.raw = rawError;
    setExtra('error.raw', rawError);
  }
}

export default APIError;
