import { useHover } from '../../Hooks';
import * as Icons from '../../Images/Icons';
import { Option } from '../Breadcrumb';
import BreadcrumbIcon from '../BreadcrumbIcon';
import { Separator, StepText, Tooltip } from './styles';

interface BreadcrumbItemProps {
  option: Option;
  index: number;
  length: number;
}

const BreadcrumbItem = ({ option, index, length }: BreadcrumbItemProps) => {
  const { componentRef, isHovering } = useHover();

  const RawIcon = Icons[option.icon];
  const isLast = index === length - 1;
  const isActive = option.status === 'active';

  return (
    <>
      <BreadcrumbIcon
        key={option.icon}
        status={option.status}
        ref={componentRef}
      >
        {isHovering ? <Tooltip>{option.tooltip}</Tooltip> : null}
        <RawIcon />
      </BreadcrumbIcon>
      {isActive ? (
        <StepText>
          Etapa {index + 1} de {length}
        </StepText>
      ) : null}
      {!isLast ? <Separator /> : null}
    </>
  );
};

export default BreadcrumbItem;
