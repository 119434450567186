import { ReactElement } from 'react';

const Edit = (): ReactElement => {
  return (
    <svg
      width='14'
      height='13'
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.67188 8.58594C9.625 8.63281 9.625 8.67969 9.625 8.72656V11.375H1.375V3.125H7.02344C7.07031 3.125 7.11719 3.125 7.16406 3.07812L7.91406 2.32812C8.03125 2.21094 7.9375 2 7.77344 2H1.375C0.742188 2 0.25 2.51562 0.25 3.125V11.375C0.25 12.0078 0.742188 12.5 1.375 12.5H9.625C10.2344 12.5 10.75 12.0078 10.75 11.375V7.97656C10.75 7.8125 10.5391 7.71875 10.4219 7.83594L9.67188 8.58594ZM13.3281 3.875C13.8672 3.33594 13.8672 2.46875 13.3281 1.92969L12.3203 0.921875C11.7812 0.382812 10.9141 0.382812 10.375 0.921875L4.23438 7.0625L4 9.19531C3.92969 9.80469 4.44531 10.3203 5.05469 10.25L7.1875 10.0156L13.3281 3.875ZM11.0312 4.57812L6.67188 8.9375L5.125 9.125L5.3125 7.57812L9.67188 3.21875L11.0312 4.57812ZM12.5312 2.72656C12.6484 2.82031 12.6484 2.96094 12.5547 3.07812L11.8281 3.80469L10.4688 2.42188L11.1719 1.71875C11.2656 1.60156 11.4297 1.60156 11.5234 1.71875L12.5312 2.72656Z'
        fill='#00ADEF'
      />
    </svg>
  );
};

export default Edit;
