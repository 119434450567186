import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const DesktopGrid = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
`;

const Grid = styled.div`
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: 2fr 1fr;
  align-items: center;

  ${media.greaterThan('medium')`${DesktopGrid}`}
`;

export default Grid;
