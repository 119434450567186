import { ReactElement } from 'react';

const RightArrow = (): ReactElement => {
  return (
    <svg
      width='17'
      height='15'
      viewBox='0 0 17 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.53357 7.5H15.4869M15.4869 7.5L8.51025 1.5M15.4869 7.5L8.51025 13.5'
        stroke='white'
        strokeOpacity='0.5'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default RightArrow;
