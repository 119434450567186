import { rgba } from 'polished';
import ReactInputMask from 'react-input-mask';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const DesktopInput = css`
  box-shadow: 0 0 0 4px ${({ theme }) => rgba(theme.colors.primary, 0.12)};
`;

interface BaseInputProps {
  $error?: boolean;
  disabled?: boolean;
}

const InputStyles = css<BaseInputProps>`
  -webkit-appearance: none;

  border: 1px solid #dcf0f8;
  box-sizing: border-box;
  border-radius: 8px;

  color: ${({ theme }) => theme.colors.grayDark};

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  outline: none;
  padding: 12px 18px;

  &:focus {
    transition: 0.2s;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 3px ${({ theme }) => rgba(theme.colors.primary, 0.12)};

    ${media.greaterThan('huge')`${DesktopInput}`}
  }

  ${({ $error, theme }) =>
    $error &&
    css`
      border-color: ${rgba(theme.colors.danger, 0.5)};
      background-color: ${rgba(theme.colors.danger, 0.02)};

      &:focus {
        border-color: ${theme.colors.danger};
        background-color: ${rgba(theme.colors.danger, 0.03)};
        box-shadow: 0 0 0 3px ${rgba(theme.colors.danger, 0.1)};

        ${media.greaterThan('huge')`
          box-shadow: 0 0 0 4px ${rgba(theme.colors.danger, 0.1)};
        `}
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.9;
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.grayExtraLight};
    `}
  
  &::placeholder {
    color: ${({ theme }) => theme.colors.grayLighten};
    opacity: 1;
  }
`;

export const AutocompleteInput = styled.input<GTMSelectData & BaseInputProps>`
  ${InputStyles}
`;

export const Input = styled.input<GTMInputData & BaseInputProps>`
  ${InputStyles}
`;

export const InputMask = styled(ReactInputMask)<GTMInputData & BaseInputProps>`
  ${InputStyles}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
