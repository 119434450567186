import styled from 'styled-components';

const ModalDescription = styled.p`
  color: ${({ theme }) => theme.colors.gray};

  font-size: 14px;
  line-height: 140%;
  text-align: center;
  margin: 0;
`;

export default ModalDescription;
