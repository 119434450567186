import { ReactElement } from 'react';

import * as S from './styles';

interface CardSectionProps {
  title: string;
  icon: ReactElement;
  content: string | { text: string; key: string }[];
}

const CardSection = ({ content, icon, title }: CardSectionProps) => {
  return (
    <S.Grid>
      {icon}
      <S.Title>{title}</S.Title>
      {Array.isArray(content) ? (
        <div>
          {content.map(({ text, key }) => (
            <S.Content key={key}>{text}</S.Content>
          ))}
        </div>
      ) : (
        <S.Content>{content}</S.Content>
      )}
    </S.Grid>
  );
};

export default CardSection;
