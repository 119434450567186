import AesJs from 'aes-js';
import CryptoJS from 'crypto-js';
import { MouseEvent } from 'react';

export const objectValuesToTrue = <T>(object: T): Record<string, true> =>
  Object.fromEntries<true>(Object.entries(object).map(([key]) => [key, true]));

export const preventClickPropagation = (ev: MouseEvent): void => {
  ev.stopPropagation();
};

export const safeStringToBool = (string: string): boolean => {
  if (string === 'true') {
    return true;
  }

  if (string === 'false') {
    return false;
  }

  throw new Error(`Unhandled value ${string}`);
};

export const removeSpecialChars = (str: string) =>
  str.replace(/\(|\)|\s|-|\.|\//g, '');

// Isso foi definido pela equipe de tagueamento
const ENCRYPTION_KEY = 'chave';

const getEncryptedKeyBuffer = () => {
  const key256 = CryptoJS.SHA256(ENCRYPTION_KEY);
  const buffer = Buffer.from(key256.toString(CryptoJS.enc.Hex), 'hex');
  return new Uint8Array(buffer).slice(0, 16);
};

const encryptedKeyBuffer = getEncryptedKeyBuffer();
const aes = new AesJs.AES(encryptedKeyBuffer);

export const encryptText = (text: string) => {
  const textAsBytes = AesJs.utils.utf8.toBytes(text);

  const encryptedBytes = aes.encrypt(AesJs.padding.pkcs7.pad(textAsBytes));
  return AesJs.utils.hex.fromBytes(encryptedBytes);
};

const isHeadOffice = (cnpj: string) =>
  cnpj.replace(/[^\d]/g, '').endsWith('0001', 12);

export const getCompanyTypeFromCNPJ = (cnpj: string): 'matriz' | 'filial' =>
  isHeadOffice(cnpj) ? 'matriz' : 'filial';
