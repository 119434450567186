import { useIsFetching, useIsMutating } from 'react-query';

const useIsLoading = () => {
  const queryCount = useIsFetching();
  const mutationCount = useIsMutating();

  return queryCount + mutationCount > 0;
};

export default useIsLoading;
