import { ReactElement } from 'react';

const ExternalLink = (): ReactElement => (
  <svg
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.8125 9.5H10.9375C10.6914 9.5 10.5 9.71875 10.5 9.9375V13H1.75V4.25H5.6875C5.90625 4.25 6.125 4.05859 6.125 3.8125V2.9375C6.125 2.71875 5.90625 2.5 5.6875 2.5H1.3125C0.574219 2.5 0 3.10156 0 3.8125V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V9.9375C12.25 9.71875 12.0312 9.5 11.8125 9.5ZM13.3438 0.75H9.84375C9.24219 0.75 8.94141 1.46094 9.37891 1.87109L10.3359 2.85547L3.69141 9.52734C3.55469 9.63672 3.47266 9.80078 3.47266 9.99219C3.47266 10.1836 3.55469 10.3477 3.69141 10.457L4.29297 11.0586C4.42969 11.1953 4.59375 11.2773 4.75781 11.2773C4.94922 11.2773 5.11328 11.1953 5.22266 11.0586L11.8945 4.41406L12.8789 5.37109C13.2891 5.78125 14 5.50781 14 4.90625V1.40625C14 1.05078 13.6992 0.75 13.3438 0.75Z'
      fill='white'
      fillOpacity='0.5'
    />
  </svg>
);

export default ExternalLink;
