import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Separator = styled.span`
  width: 2px;
  background-color: #edf3f5;
  height: 30px;
  margin: 0 12px;
`;

const StepTextDesktop = css`
  display: block;
`;

export const StepText = styled.span`
  display: none;

  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 12px;

  ${media.greaterThan('medium')`${StepTextDesktop}`}
`;

export const Tooltip = styled.span`
  isolation: isolate;
  position: absolute;
  top: -8px;
  display: inline-block;

  visibility: var(--tooltip-visibility);

  white-space: nowrap;

  font-weight: 600;
  font-size: 12px;

  color: #ffffff;
  background: #004e70;
  border-radius: 6px;

  text-align: center;
  padding: 4px 8px;

  &:before {
    --size: 8px;
    --half-size: calc(var(--size) / 2);

    content: '';

    position: absolute;
    z-index: -1;

    top: calc(100% - var(--half-size));
    left: calc(50% - var(--half-size));
    transform: rotate(45deg);

    width: var(--size);
    height: var(--size);

    background: #004e70;
  }
`;
