import { useMemo } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { useRouteManager } from '../../Context/RouteManager';
import SadFace from '../../Images/Decorations/SadFace';
import { FillButton, FormHeader, FormSubtitle, FormTitle } from '../index';
import * as S from './styles';

const FormError = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { resetNavigation } = useRouteManager();

  const errorDescription = useMemo(() => {
    if (typeof error === 'string' && error === '@bureau/data-not-found') {
      return {
        title: 'Este CPF não esta cadastrado na Porto',
        subtitle:
          'Lamentamos o incoveninte, por favor entre em contato com o suporte departamental através do e-mail: portalsolucao.paralocacao@portoseguro.com.br',
      };
    }

    return {
      title: 'Algo deu errado...',
      subtitle:
        'Lamentamos o incoveniente. Por favor, tente novamente mais tarde.',
    };
  }, [error]);

  return (
    <S.Container>
      <FormHeader>
        <FormTitle>{errorDescription?.title}</FormTitle>
        <FormSubtitle>{errorDescription?.subtitle}</FormSubtitle>
      </FormHeader>
      <S.Spacer height='75px' />
      <SadFace />
      <S.Spacer height='60px' />
      <FillButton
        data-gtm-name='voltar-para-a-pagina-inicial'
        type='button'
        onClick={resetNavigation}
      >
        <S.HomeIcon />
        Voltar para a página inicial
      </FillButton>
    </S.Container>
  );
};

export default FormError;
