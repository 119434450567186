import { forwardRef } from 'react';
import { default as GoogleReCAPTCHA } from 'react-google-recaptcha';

const ReCAPTCHA = forwardRef<GoogleReCAPTCHA | null>((props, recaptchaRef) => {
  return (
    <GoogleReCAPTCHA
      sitekey='6LdVeh8bAAAAACC1FwG1ZHfPaigsSLc48B93cR7U'
      size='invisible'
      hl='pt-BR'
      badge='bottomright'
      ref={recaptchaRef}
      tabindex={-1}
    />
  );
});

ReCAPTCHA.displayName = 'ReCAPTCHA';

export default ReCAPTCHA;
