import { useCallback, useEffect } from 'react';

const useCustomData = () => {
  const getCustomData = (): GTMCustomData => window.customData;

  const setCustomData = (data: Partial<Exclude<GTMCustomData, 'site'>>) => {
    window.customData = {
      user: {
        logado: false,
        ...data?.user,
      },
      page: {
        name: 'Cadastro de parceiros',
        product: 'imobiliaria',
        ...data?.page,
      },
      site: { brand: 'portoseguro', portal: 'corretoronline' },
    };
  };

  useEffect(() => {
    if (!getCustomData()) {
      setCustomData({});
    }
  }, []);

  const updateCustomDataUser = useCallback(
    (newUser: Partial<GTMCustomData['user']>) => {
      const currentData = getCustomData();

      setCustomData({ user: { ...currentData.user, ...newUser } });
    },
    [],
  );

  return { updateCustomDataUser };
};

export default useCustomData;
