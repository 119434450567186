import styled from 'styled-components';

export const FormDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;

  color: #718b95;
  margin: 24px 0;
`;

export default FormDescription;
