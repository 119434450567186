import { ReactElement } from 'react';

const Person = (): ReactElement => {
  return (
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.2222 17V15.2222C15.2222 14.2792 14.8476 13.3749 14.1808 12.7081C13.514 12.0413 12.6097 11.6667 11.6667 11.6667H4.55556C3.61256 11.6667 2.70819 12.0413 2.0414 12.7081C1.3746 13.3749 1 14.2792 1 15.2222V17'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.11111 8.11111C10.0748 8.11111 11.6667 6.51923 11.6667 4.55556C11.6667 2.59188 10.0748 1 8.11111 1C6.14743 1 4.55556 2.59188 4.55556 4.55556C4.55556 6.51923 6.14743 8.11111 8.11111 8.11111Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Person;
