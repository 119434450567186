import { setContext } from '@sentry/react';
import { createContext, FC, useContext, useState } from 'react';
import { useMutation } from 'react-query';

import { removeSpecialChars } from '../../Utils';
import { patchLead, postLead, postPartner } from './services';
import {
  APIPartner,
  LeadType,
  PartnerContextType,
  PartnerType,
  PolicyholderType,
} from './types';

const initialPartnerValue: PartnerType = {
  bankData: {
    bank: '',
    agency: '',
    account: '',
    accountDigit: '',
    email: '',
  },
  insuranceAgent: {
    id: '',
    name: '',
    susep: '',
    email: '',
    telephone: '',
    address: {
      zipcode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    },
  },
  policyholder: {
    type: PolicyholderType.PF,
    address: {
      zipcode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    },
    document: '',
    email: '',
    phone: '',
    lgpd: false,
    termsOfUse: false,
  },
};

const localPartnerToApiPartner = ({
  policyholder,
  insuranceAgent,
  bankData,
}: PartnerType): APIPartner => {
  if (policyholder.type === PolicyholderType.PJ) {
    return {
      name: policyholder.companyName,
      broker: insuranceAgent.susep,
      email: policyholder.email,
      phone: removeSpecialChars(policyholder.phone),
      document: removeSpecialChars(policyholder.document),
      address: policyholder.address,
      bank: {
        name: bankData.bank,
        branch: bankData.agency,
        account: bankData.account,
        digit: bankData.accountDigit,
        bank_email: bankData.email,
      },
      company: {
        patrimony: policyholder.patrimony,
        administrator: {
          name: policyholder.representative.name,
          address: policyholder.representative.address,
          email: policyholder.representative.email,
          document: removeSpecialChars(policyholder.representative.cpf),
        },
        ...(policyholder.cityRegistration
          ? { municipal_inscription: policyholder.cityRegistration }
          : {}),
        private: policyholder.companyType === 'private',
        simples_nacional: policyholder.optingSimplesNacional === 'true',
      },
    };
  }

  if (policyholder.type === PolicyholderType.PF) {
    return {
      ...(!!policyholder?.name && { name: policyholder.name }),
      broker: insuranceAgent.susep,
      email: policyholder.email,
      phone: removeSpecialChars(policyholder.phone),
      document: removeSpecialChars(policyholder.document),
      address: policyholder.address,
      bank: {
        name: bankData.bank,
        branch: bankData.agency,
        account: bankData.account,
        digit: bankData.accountDigit,
        bank_email: bankData.email,
      },
    };
  }

  throw new Error('Nao era pra cair aqui mas o js nao tem pattern matching');
};

const defaultFunction = async () => {
  throw new Error('uninitialized function');
};

const PartnerContext = createContext<PartnerContextType>({
  isLoading: false,
  partner: initialPartnerValue,
  createPartner: defaultFunction,
  createLead: defaultFunction,
  updateLead: defaultFunction,
});

export const PartnerProvider: FC = ({ children }) => {
  const [partner, _setPartner] = useState<PartnerType>(initialPartnerValue);
  const [lead, setLead] = useState<LeadType>();

  const setPartner = (partner: PartnerType) => {
    console.log();
    console.log('partner :: ', partner);
    console.log();
    _setPartner(partner);
    setContext('partner', { partner });
  };

  const partnerMutation = useMutation(postPartner);
  const createLeadMutation = useMutation(postLead);
  const updateLeadMutation = useMutation(patchLead);

  const createPartner = async (
    partnerData: Partial<PartnerType>,
    recaptchaToken: string,
  ) => {
    const newPartner = { ...partner, ...partnerData };

    setPartner(newPartner);

    return await partnerMutation.mutateAsync({
      partner: localPartnerToApiPartner(newPartner),
      recaptchaToken,
    });
  };

  const createLead = async (
    newData: Partial<PartnerType>,
    recaptchaToken: string,
  ) => {
    const newPartner = { ...partner, ...newData };

    setPartner(newPartner);

    const response = await createLeadMutation.mutateAsync({
      lead: newPartner,
      recaptchaToken,
    });

    setLead(response);
  };

  const updateLead = async (
    newData: Partial<PartnerType>,
    recaptchaToken: string,
  ) => {
    if (!lead) {
      throw new Error('Lead nao encontrado');
    }

    const newPartner = { ...partner, ...newData };

    setPartner(newPartner);

    await updateLeadMutation.mutateAsync({
      lead: newPartner,
      application: lead.application,
      id: lead.id,
      recaptchaToken,
    });
  };

  return (
    <PartnerContext.Provider
      value={{
        isLoading:
          createLeadMutation.isLoading ||
          updateLeadMutation.isLoading ||
          partnerMutation.isLoading,
        partner,
        createPartner,
        createLead,
        updateLead,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export const usePartner = () => useContext(PartnerContext);
