import { ReactElement } from 'react';

const SadFace = (): ReactElement => (
  <svg
    width='115'
    height='115'
    viewBox='0 0 115 115'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='57.5'
      cy='57.5'
      r='56'
      fill='white'
      stroke='#00ADEF'
      strokeWidth='3'
    />
    <path
      d='M34.2287 85.657C34.2287 78.0357 44.6488 66.4904 57.5026 66.4904C70.3563 66.4904 77.3511 75.2976 80.7764 85.657C72.5594 76.6667 70.3563 71.1905 57.5026 71.1905C44.6488 71.1905 39.0177 78.7202 34.2287 85.657Z'
      fill='#00ADEF'
    />
    <path
      d='M39.542 48.9653C38.2198 48.9653 36.9273 48.5732 35.828 47.8386C34.7286 47.1041 33.8718 46.06 33.3658 44.8385C32.8598 43.617 32.7274 42.2728 32.9854 40.9761C33.2433 39.6793 33.88 38.4881 34.8149 37.5532C35.7499 36.6183 36.941 35.9816 38.2378 35.7237C39.5346 35.4657 40.8787 35.5981 42.1002 36.1041C43.3217 36.6101 44.3658 37.4669 45.1004 38.5662C45.8349 39.6656 46.227 40.9581 46.227 42.2802C46.227 44.0532 45.5227 45.7536 44.269 47.0073C43.0153 48.261 41.3149 48.9653 39.542 48.9653Z'
      fill='#00ADEF'
    />
    <path
      d='M75.1372 48.9653C73.815 48.9653 72.5225 48.5732 71.4232 47.8386C70.3238 47.1041 69.467 46.06 68.961 44.8385C68.455 43.617 68.3227 42.2728 68.5806 40.9761C68.8385 39.6793 69.4752 38.4881 70.4102 37.5532C71.3451 36.6183 72.5362 35.9816 73.833 35.7237C75.1298 35.4657 76.4739 35.5981 77.6954 36.1041C78.917 36.6101 79.961 37.4669 80.6956 38.5662C81.4301 39.6656 81.8222 40.9581 81.8222 42.2802C81.8222 44.0532 81.1179 45.7536 79.8642 47.0073C78.6105 48.261 76.9102 48.9653 75.1372 48.9653Z'
      fill='#00ADEF'
    />
  </svg>
);

export default SadFace;
