import { ButtonHTMLAttributes } from 'react';

import Spinner from '../Spinner';
import * as S from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface FillButtonProps extends ButtonProps {
  loading?: boolean;
}

export const FillButton = ({
  loading,
  children,
  ...props
}: FillButtonProps &
  Omit<GTMButtonData, 'data-gtm-clicktype' | 'data-gtm-type'>) => {
  if (loading) {
    return (
      <S.FillButton
        data-gtm-type='click'
        data-gtm-clicktype='button'
        {...props}
        disabled
      >
        Carregando... <Spinner.Default spinnerColor='white' height='1rem' />
      </S.FillButton>
    );
  }

  return (
    <S.FillButton data-gtm-type='click' data-gtm-clicktype='button' {...props}>
      {children}
    </S.FillButton>
  );
};

export const OutlineButton = ({
  ...props
}: ButtonProps &
  Omit<GTMButtonData, 'data-gtm-clicktype' | 'data-gtm-type'>) => (
  <S.OutlineButton
    data-gtm-type='click'
    data-gtm-clicktype='button'
    {...props}
  />
);
