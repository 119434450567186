import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import GradientLogo from '../../Images/Logos/GradientLogo';
import GradientBackground from '../GradientBackground';

const GradientDesktop = css`
  display: block;
  padding: 40px 30px;
`;

export const Gradient = styled(GradientBackground)`
  display: none;

  ${media.greaterThan('medium')`${GradientDesktop}`}
`;

export const Title = styled.h1`
  color: #ffffff;

  font-style: normal;
  font-weight: bold;

  font-size: 2.25rem;
  line-height: 120%;

  letter-spacing: -0.03em;
  margin-top: 3rem;
`;

export const Subtitle = styled.p`
  color: #ffffff;
  opacity: 0.8;

  font-style: normal;
  font-weight: normal;

  font-size: 0.875rem;
  line-height: 140%;

  margin-top: 1.5rem;
`;

export const LogoGradient = styled(GradientLogo)`
  margin-top: 6rem;
  width: 320px;
`;
