import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.label`
  cursor: pointer;
  user-select: none;
`;

export const Field = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Thumb = styled.div`
  position: relative;

  width: 38px;
  height: 22px;
  padding: 2px;

  display: inline-flex;

  cursor: pointer;
  border-radius: 8px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.3)};
`;

export const Pin = styled.div`
  width: 18px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  border-radius: 6px;
  background-color: #fff;
  color: ${({ theme }) => rgba(theme.colors.primary, 0.5)};
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #00adef;
  margin-left: 8px;
`;

export const Input = styled.input.attrs(() => ({ type: 'checkbox' }))<
  Pick<{ disabled: boolean }, 'disabled'>
>`
  display: none;

  &:checked + ${Thumb} {
    justify-content: flex-end;

    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:checked + ${Thumb} > ${Pin} {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:checked + ${Thumb} + ${Label} {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Message = styled.span`
  margin-top: 16px;
  border-radius: 8px;
`;
