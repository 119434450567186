import styled from 'styled-components';

export const FormSubtitle = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  margin: 0;

  color: ${({ theme }) => theme.colors.grayDarker};
`;

export default FormSubtitle;
