import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    margin-top: 8px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.label<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 14px;
  line-height: 140%;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`;

export const DesktopInput = css`
  box-shadow: 0 0 0 4px ${({ theme }) => rgba(theme.colors.primary, 0.12)};
`;

export const Input = styled.input`
  appearance: none;

  margin-right: 8px;
  padding: 8px;

  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;

  display: inline-block;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  // check icon
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  &:checked:after {
    display: block;
  }

  &:focus {
    transition: 0.2s;
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => rgba(theme.colors.primary, 0.12)};

    ${media.greaterThan('huge')`${DesktopInput}`}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
