import { FC } from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import GlobalStyle from './globalStyle';
import theme from './theme';

export const ThemeProvider: FC = ({ children }) => (
  <SCThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </SCThemeProvider>
);
