import styled from 'styled-components';

const GradientBackground = styled.aside`
  position: relative;
  background: linear-gradient(
    233.94deg,
    ${({ theme }) => theme.colors.primaryDark} 11.46%,
    ${({ theme }) => theme.colors.primary} 100%
  );
  height: 100%;
`;

export default GradientBackground;
