import { ReactElement } from 'react';

const Home = (): ReactElement => (
  <svg
    height='15'
    viewBox='0 0 16 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.10938 3.4082L3.4375 7.26758V11.4062C3.4375 11.6348 3.61523 11.8125 3.84375 11.8125H6.6875C6.89062 11.8125 7.06836 11.6348 7.06836 11.4062V8.96875C7.06836 8.76562 7.27148 8.5625 7.47461 8.5625H9.09961C9.32812 8.5625 9.50586 8.76562 9.50586 8.96875V11.4062C9.50586 11.6348 9.70898 11.8125 9.91211 11.8125H12.7812C12.9844 11.8125 13.1875 11.6348 13.1875 11.4062V7.24219L8.49023 3.4082C8.43945 3.35742 8.36328 3.33203 8.3125 3.33203C8.23633 3.33203 8.16016 3.35742 8.10938 3.4082ZM15.498 6.02344L13.3906 4.27148V0.767578C13.3906 0.589844 13.2383 0.462891 13.0859 0.462891H11.6641C11.4863 0.462891 11.3594 0.589844 11.3594 0.767578V2.5957L9.07422 0.716797C8.87109 0.564453 8.5918 0.462891 8.3125 0.462891C8.00781 0.462891 7.72852 0.564453 7.52539 0.716797L1.10156 6.02344C1.02539 6.07422 0.974609 6.17578 0.974609 6.25195C0.974609 6.32812 1.02539 6.4043 1.05078 6.45508L1.71094 7.24219C1.76172 7.31836 1.83789 7.34375 1.93945 7.34375C2.01562 7.34375 2.0918 7.31836 2.14258 7.26758L8.10938 2.36719C8.16016 2.31641 8.23633 2.29102 8.3125 2.29102C8.36328 2.29102 8.43945 2.31641 8.49023 2.36719L14.457 7.26758C14.5078 7.31836 14.584 7.34375 14.6602 7.34375C14.7617 7.34375 14.8379 7.31836 14.8887 7.24219L15.5488 6.45508C15.5996 6.4043 15.625 6.32812 15.625 6.25195C15.625 6.17578 15.5742 6.07422 15.498 6.02344Z'
      fill='white'
      fillOpacity='0.5'
    />
  </svg>
);

export default Home;
