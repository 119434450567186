import { useField } from 'formik';
import { FaCheck, FaTimes } from 'react-icons/fa';

import ErrorLabel from '../../ErrorLabel';
import * as S from './styles';

interface ToggleProps extends Omit<GTMToggleData, 'data-gtm-type'> {
  name: string;
  label?: string;
  disabled?: boolean;
}

const Toggle = ({ disabled, label, name }: ToggleProps) => {
  const [field, meta] = useField(name);

  return (
    <S.Container>
      <S.Field>
        <S.Input
          data-gtm-type='toggle'
          {...field}
          checked={field.value}
          disabled={!!disabled}
        />
        <S.Thumb>
          <S.Pin>{field.value ? <FaCheck /> : <FaTimes />}</S.Pin>
        </S.Thumb>
        {label && <S.Label>{label}</S.Label>}
      </S.Field>
      <ErrorLabel>{meta.error}</ErrorLabel>
    </S.Container>
  );
};

export default Toggle;
