import { rgba } from 'polished';
import styled from 'styled-components';

import { FlagType } from './index';

interface FlagTypeProps {
  $type: FlagType;
}

export const Flag = styled.div<FlagTypeProps>`
  background-color: ${({ theme, $type }) => {
    switch ($type) {
      case FlagType.SUCCESS:
        return rgba(theme.colors.success, 0.1);
      case FlagType.DANGER:
      default:
        return rgba(theme.colors.danger, 0.1);
    }
  }};

  display: flex;
  padding: 5px 10px;
  margin-bottom: 24px;
  border-radius: 7px;
`;

export const FlagDecoration = styled.div<FlagTypeProps>`
  width: 0;
  padding-right: 4px;
  margin-right: 10px;
  background-color: ${({ theme, $type }) => {
    switch ($type) {
      case FlagType.SUCCESS:
        return rgba(theme.colors.success, 0.6);
      case FlagType.DANGER:
      default:
        return rgba(theme.colors.danger, 0.6);
    }
  }};
`;

export const FlagText = styled.span<FlagTypeProps>`
  font-size: 14px;
  line-height: 19px;
  margin-left: 4px;
`;
