import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { GradientBackground } from '../../../../shared/Components';

const BackgroundDesktop = css`
  clip-path: ellipse(58% 110% at 41% 45%);
`;

const Background = styled(GradientBackground)`
  clip-path: ellipse(110% 58% at 45% 41%);
  ${media.greaterThan('medium')`${BackgroundDesktop}`}
`;

export default Background;
