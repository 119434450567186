import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.text};
  }

  h1, h2, h3, h4, h5, h6, button {
    font-family: ${({ theme }) => theme.fonts.title};
  }
`;

export default GlobalStyle;
