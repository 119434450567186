import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const BaseButton = styled.button`
  background-color: white;
  outline: none;
  border: 1px solid transparent;
  border-radius: 12px;

  font-style: normal;
  font-size: 14px;
  line-height: 16px;

  padding: 16px 21px;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 4px ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`;

export const FillButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

  color: white;
  font-weight: bold;
`;

export const OutlineButton = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  box-sizing: border-box;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.primary};

  font-weight: 500;
`;
