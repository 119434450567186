import './index.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import Router from './router';
import { ThemeProvider } from './styles';

const tagManagerArgs = {
  gtmId: 'GTM-55V3JH7',
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: 'https://d627882345344edebb07080d3cc01c4f@o307280.ingest.sentry.io/5902194',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT || 'production',
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

ReactDOM.render(
  <StrictMode>
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);
