import { FC, SelectHTMLAttributes } from 'react';

import ErrorLabel from '../ErrorLabel';
import InputLabel from '../InputLabel';
import * as S from './styles';

export type SelectProps = {
  label: string;
  optional?: boolean;
  error?: string;
  name?: string;
} & Pick<
  SelectHTMLAttributes<HTMLSelectElement>,
  'placeholder' | 'id' | 'onChange' | 'value' | 'disabled' | 'onBlur'
> &
  Omit<GTMInputData, 'data-gtm-form'>;

const Select: FC<SelectProps> = ({
  name,
  error,
  label,
  optional,
  children,
  id = name,
  ...selectProps
}) => {
  return (
    <S.Container>
      <InputLabel htmlFor={id}>
        {label}
        {!optional ? ' *' : ''}
      </InputLabel>
      <S.SelectContainer>
        <S.Select
          error={error}
          id={id}
          {...selectProps}
          required
          data-gtm-form='input'
        >
          <option value='' disabled hidden>
            {selectProps.placeholder}
          </option>
          {children}
        </S.Select>
      </S.SelectContainer>
      <ErrorLabel>{error}</ErrorLabel>
    </S.Container>
  );
};

export default Select;
