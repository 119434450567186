import Autocomplete from './Autocomplete';
import Checkbox from './Checkbox';
import Input from './Input';
import Radio from './Radio';
import Select from './Select';
import Toggle from './Toggle';

const Form = {
  Autocomplete,
  Checkbox,
  Input,
  Radio,
  Select,
  Toggle,
};

export default Form;
