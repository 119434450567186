import { DefaultTheme } from 'styled-components';

import fonts from './elements/fonts';
import colors from './elements/palette';

const theme: DefaultTheme = {
  colors,
  fonts,
};

export default theme;
