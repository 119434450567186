import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
`;

export const Text = styled.p`
  margin: 0 8px;
  padding: 0;

  color: ${({ theme }) => theme.colors.grayLight};
`;
