import styled from 'styled-components';

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 40px;

  > :not(:first-child) {
    margin-top: 12px;
  }
`;

export default ModalButtonContainer;
