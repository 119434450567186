import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const ModalDesktop = css`
  overflow: auto;
  height: 100%;
  min-height: 100vh;
  position: fixed;
`;

export const Modal = styled.div`
  width: 100vw;
  min-height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 100;
  inset: 0;
  display: flex;

  justify-content: center;
  align-items: baseline;

  ${media.greaterThan('medium')`${ModalDesktop}`}
`;
