import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import DefaultLogo from '../../../../shared/Images/Logos/DefaultLogo';

const TextLogoDesktop = css`
  display: none;
`;

const TextLogo = styled(DefaultLogo)`
  width: 5.75rem;
  ${media.greaterThan('medium')`${TextLogoDesktop}`}
`;

export default TextLogo;
