import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const LayoutGridDesktop = css`
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
`;

export const LayoutGrid = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;

  ${media.greaterThan('medium')`${LayoutGridDesktop}`}
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 632px;
  width: 100%;
  min-height: 100vh;
  height: 100%;

  padding: 32px 16px 80px;
  margin: 0 auto;
`;
