import { ReactElement } from 'react';

import * as Icons from '../../Images/Icons';
import { BreadcrumbIconProps } from '../BreadcrumbIcon/styles';
import BreadcrumbItem from '../BreadcrumbItem';
import { Container } from './styles';

export type Option = {
  icon: keyof typeof Icons;
  status?: BreadcrumbIconProps['status'];
  tooltip: string;
};

type BreadcrumbProps = {
  options: readonly Option[];
};

const Breadcrumb = ({ options }: BreadcrumbProps): ReactElement => {
  const { length } = options;
  return (
    <Container>
      {options.map((option, index) => (
        <BreadcrumbItem
          index={index}
          length={length}
          option={option}
          key={option.icon}
        />
      ))}
    </Container>
  );
};

export default Breadcrumb;
