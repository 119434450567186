import { rgba } from 'polished';
import styled, { DefaultTheme, keyframes } from 'styled-components';

const SpinKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Fade = styled.div<{
  background?: keyof DefaultTheme['colors'];
  spinnerColor?: keyof DefaultTheme['colors'];
}>`
  --bg: ${({ theme, background }) => theme.colors[background ?? 'white']};
  --spinner-color: ${({ theme, spinnerColor }) =>
    theme.colors[spinnerColor ?? 'primary']};

  font-size: 16px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    var(--spinner-color) 10%,
    ${({ theme, spinnerColor }) =>
        rgba(theme.colors[spinnerColor ?? 'primary'], 0.2)}
      40%
  );
  position: relative;
  animation: ${SpinKeyframes} 1s infinite linear;

  &:before {
    width: 50%;
    height: 50%;
    background: var(--spinner-color);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: var(--bg);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export const BetterSpinnerContainer = styled.svg`
  animation: ${SpinKeyframes} 1s infinite linear;
`;
