import { ReactElement } from 'react';

const Suitcase = (): ReactElement => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6082 10.1721L10.6082 10.1721C10.2103 10.288 9.78975 10.288 9.39177 10.1721L9.39176 10.1721L2.74967 8.23814C1.96725 8.01034 1.42012 7.28123 1.42012 6.4663V5.52637C1.42012 5.21171 1.67633 4.9555 1.99099 4.9555H18.009C18.3236 4.9555 18.5799 5.21171 18.5799 5.52637V6.4663C18.5799 7.28123 18.0327 8.01038 17.2503 8.23818L10.6082 10.1721ZM2.31072 9.74569L2.31073 9.74569C3.80818 10.1817 4.9847 10.5264 5.91466 10.7988C7.2619 11.1935 8.09165 11.4366 8.62999 11.586C9.08417 11.7122 9.33821 11.7738 9.52353 11.8031C9.69277 11.8299 9.80465 11.8296 9.94677 11.8292C9.96401 11.8292 9.98169 11.8292 9.99997 11.8292C10.0192 11.8292 10.0378 11.8292 10.0558 11.8293C10.1985 11.8297 10.3107 11.83 10.4789 11.8035C10.6641 11.7743 10.9171 11.7129 11.3702 11.587C11.8959 11.4408 12.7008 11.2049 13.9983 10.8245C14.9412 10.5482 16.1442 10.1955 17.6892 9.74569L17.6473 9.60167L17.6892 9.74569C18.008 9.65286 18.3068 9.5166 18.5798 9.34136V15.7097C18.5799 16.0243 18.3236 16.2806 18.009 16.2806H1.99099C1.67636 16.2806 1.42012 16.0243 1.42012 15.7097V9.34134C1.69316 9.51659 1.99195 9.65286 2.31072 9.74569ZM18.009 3.38538H13.8495V1.79884C13.8495 0.889147 13.1096 0.149347 12.1999 0.149347H7.80001C6.89032 0.149347 6.15047 0.889147 6.15047 1.79884V3.38538H1.99099C0.810288 3.38538 -0.15 4.34571 -0.15 5.52637V15.7097C-0.15 16.8904 0.810292 17.8506 1.99099 17.8506H18.009C19.1896 17.8506 20.15 16.8904 20.15 15.7097V5.52637C20.15 4.34571 19.1897 3.38538 18.009 3.38538ZM7.72059 1.79884C7.72059 1.75522 7.75632 1.71947 7.80001 1.71947H12.1999C12.2436 1.71947 12.2794 1.75525 12.2794 1.79884V3.38538H7.72059V1.79884Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.3'
      />
    </svg>
  );
};

export default Suitcase;
