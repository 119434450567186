import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import DefaultLogo from '../../../../shared/Images/Logos/DefaultLogo';

const LogoDesktop = css`
  display: block;
`;

const Logo = styled(DefaultLogo)`
  position: absolute;
  top: calc(0% + var(--padding-size));
  right: calc(0% + var(--padding-size));
  width: 136px;
  display: none;

  ${media.greaterThan('medium')`${LogoDesktop}`}
`;

export default Logo;
