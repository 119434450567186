import { rgba } from 'polished';
import { IoHome } from 'react-icons/io5';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Spacer = styled.div<{ height: string }>`
  width: 100%;
  height: ${({ height }) => height};
`;

export const HomeIcon = styled(IoHome)`
  color: ${({ theme }) => rgba(theme.colors.white, 0.7)};
  font-size: 1rem;
`;
