import { Spinner } from '../index';
import * as S from './styles';

interface LoadingAddressProps {
  className?: string;
  children?: string;
}

const LoadingText = ({
  className,
  children = 'Buscando endereço. Por favor, aguarde...',
}: LoadingAddressProps) => {
  return (
    <S.Container className={className}>
      <Spinner.Default />
      <S.Text>{children}</S.Text>
    </S.Container>
  );
};

export default LoadingText;
