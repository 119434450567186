import { useEffect, useRef, useState } from 'react';

const useHover = () => {
  const [isHovering, setIsHovering] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const onHover = () => setIsHovering(true);
  const onLeave = () => setIsHovering(false);

  useEffect(() => {
    const component = componentRef.current;

    if (component) {
      component.addEventListener('mouseover', onHover);
      component.addEventListener('mouseleave', onLeave);

      return () => {
        component.removeEventListener('mouseover', onHover);
        component.removeEventListener('mouseleave', onLeave);
      };
    }
  }, []);

  return { isHovering, componentRef };
};

export default useHover;
