import { ReactElement } from 'react';

const Suitcase = (): ReactElement => {
  return (
    <svg
      width='19'
      height='17'
      viewBox='0 0 19 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.858 9.2882C15.8124 9.26519 15.7667 9.25371 15.7206 9.25371C15.6215 9.25371 15.5341 9.29177 15.458 9.36804L14.7267 10.0992C14.6584 10.1678 14.624 10.2515 14.624 10.3506V13.2523C14.624 13.7552 14.4454 14.1853 14.0872 14.5434C13.7293 14.9014 13.2988 15.0804 12.7961 15.0804H3.29043C2.78765 15.0804 2.35727 14.9014 1.99932 14.5434C1.64129 14.1853 1.46238 13.7552 1.46238 13.2523V3.74688C1.46238 3.24419 1.64129 2.81385 1.99932 2.45578C2.35727 2.09779 2.78765 1.91884 3.29043 1.91884H12.7962C12.9638 1.91884 13.1351 1.94169 13.3103 1.98743C13.356 2.00255 13.3903 2.01028 13.4128 2.01028C13.512 2.01028 13.5996 1.9723 13.6758 1.89615L14.2356 1.33631C14.3269 1.24504 14.3613 1.13451 14.3383 1.00513C14.3154 0.883244 14.2468 0.795607 14.1326 0.742345C13.7215 0.552185 13.2757 0.456665 12.7959 0.456665H3.29043C2.38404 0.456665 1.609 0.77848 0.965366 1.42211C0.321815 2.06582 0 2.84074 0 3.74716V13.2529C0 14.1591 0.321815 14.9341 0.965406 15.5778C1.60904 16.2215 2.38408 16.5434 3.29047 16.5434H12.7961C13.7024 16.5434 14.4775 16.2215 15.1211 15.5778C15.7648 14.9341 16.0869 14.1592 16.0869 13.2529V9.61946C16.0868 9.45964 16.0103 9.34927 15.858 9.2882Z'
        fill='currentColor'
      />
      <path
        d='M18.7258 2.73023L17.469 1.47334C17.2862 1.29059 17.069 1.19919 16.8175 1.19919C16.5664 1.19919 16.349 1.29059 16.1664 1.47334L8.77426 8.86544L5.76963 5.86062C5.58679 5.67774 5.36966 5.58647 5.1184 5.58647C4.86709 5.58647 4.65004 5.67774 4.46716 5.86062L3.21043 7.11735C3.02764 7.30015 2.93616 7.51728 2.93616 7.7687C2.93616 8.02001 3.02764 8.23706 3.21043 8.41993L8.12318 13.3327C8.30602 13.5156 8.52315 13.6068 8.77442 13.6068C9.02568 13.6068 9.24277 13.5156 9.42565 13.3327L18.7257 4.03266C18.9084 3.8499 19 3.63273 19 3.38142C19 3.13012 18.9086 2.91299 18.7258 2.73023Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Suitcase;
