import { rgba } from 'polished';
import styled from 'styled-components';

export const Button = styled.button`
  padding: 4px 8px;
  background-color: transparent;
  outline: none;
  border: none;
  box-shadow: none;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  }
`;

export const ButtonText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-left: 8px;

  color: ${({ theme }) => theme.colors.primary};
`;
