import customFetch from './customFetch';

type FetcherConfig<T extends unknown> = {
  body?: T;
} & Omit<RequestInit, 'body'>;

type PathString = `/${string}`;

const API_KEY = '888a9916-4fba-47f5-97f5-2f411c4e318e';

const API_URL = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return 'https://api.solucoesparalocacao.com.br/v2';
  }

  return 'https://api-stg.solucoesparalocacao.com.br/v2';
};

type CancelablePromise<T> = Promise<T> & { cancel: () => void };

export const fetcher = <
  TResponseType extends unknown,
  TErrorType extends GenericRecord,
  TBodyType extends unknown = unknown,
>(
  endpoint: PathString,
  fetcherConfig: FetcherConfig<TBodyType> = {},
): CancelablePromise<TResponseType> => {
  const controller = new AbortController();

  const config = {
    method: fetcherConfig.body ? 'POST' : 'GET',
    ...fetcherConfig,
    headers: {
      'X-API-Key': API_KEY,
      'content-type': 'application/json',
      Accept: 'application/json',
      ...fetcherConfig.headers,
    },
    signal: controller.signal,
  };

  const baseURL = API_URL();

  const url = `${baseURL}${endpoint}`;

  const fetchPromise = customFetch<TResponseType, TErrorType, TBodyType>(
    url.toString(),
    config,
  ) as CancelablePromise<TResponseType>;

  fetchPromise.cancel = controller.abort;

  return fetchPromise;
};
