import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-template-rows: 16px 1fr;
  grid-template-areas: 'icon title' '. text';
  align-items: center;

  > :nth-child(1) {
    grid-area: icon;
    width: 12px;
    color: ${({ theme }) => theme.colors.primary};
  }

  > :nth-child(2) {
    grid-area: title;
  }

  > :nth-child(3) {
    grid-area: text;
  }
`;

export const Content = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 19px;

  color: ${({ theme }) => theme.colors.grayDark};
`;

export const Title = styled(Content)`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: ${({ theme }) => theme.colors.grayDark};
`;
