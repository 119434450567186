import { ReactElement } from 'react';

const Dollar = (): ReactElement => {
  return (
    <svg
      width='11'
      height='19'
      viewBox='0 0 11 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.4364 1V17.2667'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.13336 3.95755H3.58789C2.90154 3.95755 2.2433 4.2302 1.75797 4.71552C1.27265 5.20085 1 5.85909 1 6.54544C1 7.23179 1.27265 7.89003 1.75797 8.37535C2.2433 8.86067 2.90154 9.13333 3.58789 9.13333H7.28487C7.97122 9.13333 8.62946 9.40598 9.11478 9.8913C9.60011 10.3766 9.87276 11.0349 9.87276 11.7212C9.87276 12.4076 9.60011 13.0658 9.11478 13.5511C8.62946 14.0365 7.97122 14.3091 7.28487 14.3091H1'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Dollar;
