export interface AddressType {
  zipcode: string;
  street: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
}

export enum PolicyholderType {
  PF = 'PF',
  PJ = 'PJ',
}

export interface PolicyholderBaseType {
  document: string;
  email: string;
  phone: string;
  termsOfUse?: boolean;
  lgpd?: boolean;
  address: AddressType;
}

export interface PolicyholderPJType extends Required<PolicyholderBaseType> {
  type: PolicyholderType.PJ;
  companyName: string;
  companyType: string;
  cityRegistration?: string;
  patrimony: '001' | '002' | '003' | '004' | '005';
  optingSimplesNacional: string;
  representative: {
    cpf: string;
    name: string;
    email: string;
    foreign: boolean;
    address: AddressType;
  };
}

export interface PolicyholderPFType extends Required<PolicyholderBaseType> {
  type: PolicyholderType.PF;
  name?: string;
}

export interface InsuranceAgentType {
  id: string;
  name: string;
  susep: string;
  email: string;
  telephone: string;
  address?: AddressType;
}

export interface BankDataType {
  bank: string;
  agency: string;
  account: string;
  accountDigit: string;
  email: string;
}

export interface PartnerType extends GenericRecord {
  policyholder: PolicyholderPFType | PolicyholderPJType;
  insuranceAgent: InsuranceAgentType;
  bankData: BankDataType;
}

export interface LeadType {
  id: string;
  created_at: FirebaseDate;
  application: string;
}

export interface CreateLeadData {
  lead: Partial<PartnerType>;
  recaptchaToken: string;
}

export interface UpdateLeadData
  extends CreateLeadData,
    Omit<LeadType, 'created_at'> {}

export interface APIPartner {
  name?: string; // quando for cpf obrigatorio
  email: string;
  phone: string;
  document: string;
  address: AddressType;
  bank: {
    name: string;
    branch: string;
    account: string;
    digit: string;
    bank_email: string;
  };
  broker: string; // SUSEP
  company?: {
    private: boolean;
    patrimony?: '000' | '001' | '002' | '003' | '004' | '005';
    simples_nacional: boolean;
    municipal_inscription?: string;
    administrator: {
      document: string;
      name: string;
      email: string;
      address: AddressType;
    };
  };
}

export interface PartnerContextType {
  partner: PartnerType;
  isLoading: boolean;
  createPartner: (
    partnerData: Partial<PartnerType>,
    recaptchaToken: string,
  ) => Promise<APIPartner>;
  createLead: (
    partnerData: Partial<PartnerType>,
    recaptchaToken: string,
  ) => Promise<void>;
  updateLead: (
    partnerData: Partial<PartnerType>,
    recaptchaToken: string,
  ) => Promise<void>;
}
