import React, { FC, ReactElement } from 'react';

import DefaultLogo from '../../Images/Logos/DefaultLogo';
import { Gradient, LogoGradient, Subtitle, Title } from './styles';

const GradientAside: FC = (): ReactElement => {
  return (
    <Gradient>
      <DefaultLogo fillColor='#FFFFFF' width='92' />
      <Title>Cadastro de parceiros</Title>
      <Subtitle>
        Uma série de facilidades e benefícios para sua imobiliária? Quem tem
        Porto, tem!
      </Subtitle>
      <LogoGradient />
    </Gradient>
  );
};

export default GradientAside;
