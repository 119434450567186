import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export enum BreadcrumbIconStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  UNTOUCHED = 'untouched',
}

export type BreadcrumbIconProps = {
  status?: BreadcrumbIconStatus;
};

const ActiveIcon = css`
  box-shadow: 0 2px 12px ${({ theme }) => rgba(theme.colors.grayDark, 0.2)};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;

const UntouchedIcon = css`
  color: ${({ theme }) => theme.colors.grayExtraLight};
`;

const CompletedIcon = css`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
`;

export const BreadcrumbIcon = styled.div<BreadcrumbIconProps>`
  --tooltip-visibility: hidden;

  &:hover {
    --tooltip-visibility: visible;
  }

  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;

  ${({ status }) => {
    switch (status) {
      case 'active':
        return ActiveIcon;
      case 'completed':
        return CompletedIcon;
      case 'untouched':
      default:
        return UntouchedIcon;
    }
  }}
`;
