import { Spinner } from '../index';
import { LoadingContainer } from './styles';

const FormLoader = () => (
  <LoadingContainer>
    <Spinner.Default height='4rem' />
  </LoadingContainer>
);

export default FormLoader;
