import { DefaultTheme } from 'styled-components';

const palette: DefaultTheme['colors'] = {
  success: '#01cc88',
  warning: '#FF9C41',
  danger: '#FA5C5C',
  info: '#00d0ff',

  white: '#fff',
  black: '#000',

  primaryDarker: '#00688F',
  primaryDark: '#2C6BFF',
  primary: '#00ADEF',
  primaryLighten: '#66CEF5',

  grayDarker: '#123440',
  grayDark: '#244A57',
  gray: '#52676F',
  grayLight: '#718B95',
  grayLighten: '#A0AFB4',
  grayExtraLight: '#D4DCDF',
};

export default palette;
