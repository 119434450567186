import { ReactElement } from 'react';

const LeftArrow = (): ReactElement => (
  <svg
    width='15'
    height='14'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.8282 7L1.06378 7M1.06378 7L7.44601 13M1.06378 7L7.44601 1'
      stroke='#009CD7'
      strokeOpacity='0.4'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default LeftArrow;
