import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const DesktopTitle = css`
  font-size: 4.5rem;
  text-align: left;
`;

const Title = styled.h1`
  color: white;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: -0.03em;
  font-size: 3rem;
  text-align: center;

  margin: 0 0 16px;

  ${media.greaterThan('medium')`${DesktopTitle}`}
`;

export default Title;
