import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const DesktopContainer = css`
  margin-top: 32px;
`;

export const Container = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;

  > h4 {
    margin-top: 4px;
  }

  ${media.greaterThan('medium')`${DesktopContainer}`}
`;
