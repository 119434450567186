import { useField } from 'formik';
import { ReactElement } from 'react';

import Checkbox, { CheckboxProps } from '../../Checkbox';

interface FormCheckboxProps extends CheckboxProps {
  name: string;
}
const FormCheckbox = ({ name, ...props }: FormCheckboxProps): ReactElement => {
  const [field, meta] = useField(name);

  return (
    <Checkbox
      {...field}
      {...props}
      error={meta.touched ? meta.error : undefined}
    />
  );
};

export default FormCheckbox;
