import { FC, InputHTMLAttributes } from 'react';

import ErrorLabel from '../ErrorLabel';
import * as S from './styles';

export type CheckboxProps = {
  error?: string;
  name?: string;
  checked?: boolean;
} & Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'id' | 'onChange' | 'disabled'
> &
  GTMCheckboxData;

const Checkbox: FC<CheckboxProps> = ({
  children,
  error,
  id,
  name = id,
  disabled,
  ...props
}) => {
  return (
    <S.Container>
      <S.CheckboxContainer>
        <S.Input
          id={id}
          name={name}
          type='checkbox'
          {...props}
          disabled={disabled}
        />
        <S.Label htmlFor={id} disabled={disabled}>
          {children}
        </S.Label>
      </S.CheckboxContainer>
      <ErrorLabel>{error}</ErrorLabel>
    </S.Container>
  );
};

export default Checkbox;
