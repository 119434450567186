import { HTMLAttributes, ReactElement } from 'react';

import { EditIcon } from '../../Images/Icons';
import * as S from './styles';

const EditButton = ({
  ...props
}: HTMLAttributes<HTMLButtonElement>): ReactElement => {
  return (
    <S.Button
      type='button'
      data-gtm-type='click'
      data-gtm-clicktype='button'
      data-gtm-name='editar'
      {...props}
    >
      <EditIcon />
      <S.ButtonText>Editar</S.ButtonText>
    </S.Button>
  );
};

export default EditButton;
