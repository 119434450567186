import styled from 'styled-components';

const FormTitle = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.colors.primaryDarker};
`;

export default FormTitle;
