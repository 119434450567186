import { FC, ReactElement } from 'react';

import Routes from '../../router/routes';
import { FillButton } from '../../shared/Components';
import { useRouteManager } from '../../shared/Context/RouteManager';
import useCustomData from '../../shared/Hooks/useCustomData';
import Candle from '../../shared/Images/Decorations/Candle';
import { RightArrowIcon } from '../../shared/Images/Icons';
import Background from './Components/Background';
import BackgroundLogo from './Components/BackgroundLogo';
import ContentContainer from './Components/ContentContainer';
import Grid from './Components/Grid';
import Logo from './Components/Logo';
import Subtitle from './Components/Subtitle';
import TextContainer from './Components/TextContainer';
import TextLogo from './Components/TextLogo';
import Title from './Components/Title';

const Home: FC = (): ReactElement => {
  const history = useRouteManager();

  useCustomData();

  const navigateToPolicyHolder = () =>
    history.navigateToRoute(Routes.POLICYHOLDER);

  return (
    <Grid>
      <Background>
        <BackgroundLogo />
        <TextContainer>
          <TextLogo fillColor='#FFFFFF' />
          <Title>Cadastro de parceiros</Title>
          <Subtitle>
            Uma série de facilidades e benefícios para sua imobiliária? Quem tem
            Porto, tem!
          </Subtitle>
        </TextContainer>
      </Background>
      <ContentContainer>
        <Candle />
        <Logo fillColor='#00ADEF' />
        <FillButton
          onClick={navigateToPolicyHolder}
          data-gtm-name='iniciar-cadastro'
        >
          Iniciar Cadastro <RightArrowIcon />
        </FillButton>
      </ContentContainer>
    </Grid>
  );
};

export default Home;
