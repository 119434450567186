import { useField } from 'formik';

import Input from '../../Input';
import { InputProps } from '../../Input/types';

interface FormInputProps extends Omit<InputProps, 'data-gtm-form'> {
  name: string;
}

const FormInput = ({ name, ...props }: FormInputProps) => {
  const [field, meta] = useField(name);

  return (
    <Input
      {...field}
      {...props}
      error={meta.touched ? meta.error : undefined}
    />
  );
};

export default FormInput;
