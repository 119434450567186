import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const TextContainerDesktop = css`
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 520px;
  margin: 0 auto;
  padding: 32px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  ${media.greaterThan('medium')`${TextContainerDesktop}`};
`;

export default TextContainer;
