import { FC, ReactElement } from 'react';

import * as S from './styles';

interface FormSeparatorProps {
  wrap?: boolean;
}

const FormSeparator: FC<FormSeparatorProps> = ({
  children,
  wrap = false,
}): ReactElement => {
  return (
    <S.Container>
      <S.InfoIcon />
      <S.Text nowrap={!wrap}>{children}</S.Text>
      <S.Line />
    </S.Container>
  );
};

export default FormSeparator;
