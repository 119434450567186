import styled from 'styled-components';

const ModalTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grayDark};

  font-size: 20px;
  line-height: 130%;
  margin-block: 8px;
`;

export default ModalTitle;
