import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { AlternativeInfoIcon } from '../../Images/Icons';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 16px fit-content(0px) 1fr;
  justify-items: center;
  align-items: center;
`;

export const InfoIcon = styled(AlternativeInfoIcon)``;

const TextDesktop = css`
  white-space: nowrap;
`;

export const Text = styled.span<{ nowrap: boolean }>`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.grayDarker};
  margin-left: 8px;
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'normal')};

  ${media.greaterThan('medium')`${TextDesktop}`}
`;

export const Line = styled.span`
  height: 0;
  width: 100%;
  margin-left: 13px;
  border: 1px solid #edf3f5;
`;
