import APIError from './APIError';

const jsonParseSafe = <T extends unknown>(data: string): T | string => {
  try {
    return JSON.parse(data) as T;
  } catch {
    return data;
  }
};

type CustomInit<TBodyType extends unknown> = Omit<RequestInit, 'body'> & {
  body?: TBodyType;
};

const customFetch = <
  TResponseType extends unknown,
  TErrorType extends GenericRecord,
  TBodyType extends unknown = unknown,
>(
  input: RequestInfo,
  init?: CustomInit<TBodyType> | undefined,
): Promise<TResponseType> => {
  const requestInit: RequestInit | undefined = init
    ? { ...init, body: JSON.stringify(init.body) }
    : undefined;

  return fetch(input, requestInit).then(async (res) => {
    if (res.ok && res.body) {
      const responseText = await res.text();

      try {
        return JSON.parse(responseText) as TResponseType;
      } catch (error) {
        return responseText as TResponseType;
      }
    } else {
      const text = await res.text();

      if (!text) {
        return Promise.reject(new APIError('Erro inesperado'));
      }

      if (!!text && text.includes('@bureau/data-not-found')) {
        return Promise.reject('@bureau/data-not-found');
      }

      const error = jsonParseSafe<TErrorType>(text);

      if (typeof error === 'string') {
        return Promise.reject(new APIError(error));
      }

      return Promise.reject(new APIError('APIError', error));
    }
  });
};

export default customFetch;
