import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Options = styled.ul<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    !isOpen
      ? css`
          display: none;
        `
      : ''}

  position: absolute;
  z-index: 10;
  margin-top: 4px;
  width: 100%;
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-height: 400px;
  border-radius: 6px;
  padding: 4px;
  overflow: auto;
`;

const SelectedOption = css`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

const HighlightedOption = css`
  color: ${({ theme }) => theme.colors.grayDarker};
  background-color: ${({ theme }) => theme.colors.grayExtraLight};
`;

export const Option = styled.li<{
  isHighlighted: boolean;
  isSelected: boolean;
}>`
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
  user-select: none;
  position: relative;
  padding: 8px 12px;
  list-style: none;

  ${({ isHighlighted }) => (isHighlighted ? HighlightedOption : '')}
  ${({ isSelected }) => (isSelected ? SelectedOption : '')}
`;
