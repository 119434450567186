import { captureException, setContext } from '@sentry/react';
import { ComponentProps, FC, ReactElement, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route } from 'react-router-dom';

import Routes from '../../../router/routes';
import Breadcrumb, { Option } from '../Breadcrumb';
import { BreadcrumbIconStatus } from '../BreadcrumbIcon/styles';
import GradientAside from '../GradientAside';
import { FormError, FormLoader } from '../index';
import { FormContainer, LayoutGrid } from './styles';

type RouteProps = ComponentProps<typeof Route>;

type FormLayoutProps = {
  currentStep: number;
  path: Routes;
} & Omit<RouteProps, 'path'>;

const options: readonly Option[] = [
  {
    icon: 'InfoIcon',
    status: BreadcrumbIconStatus.UNTOUCHED,
    tooltip: 'Identificação',
  },
  {
    icon: 'PersonIcon',
    status: BreadcrumbIconStatus.UNTOUCHED,
    tooltip: 'Dados Complementares',
  },
  {
    icon: 'SuitcaseIcon',
    status: BreadcrumbIconStatus.UNTOUCHED,
    tooltip: 'Escolha do Corretor',
  },
  {
    icon: 'DollarIcon',
    status: BreadcrumbIconStatus.UNTOUCHED,
    tooltip: 'Dados Bancários',
  },
  {
    icon: 'CheckboxIcon',
    status: BreadcrumbIconStatus.UNTOUCHED,
    tooltip: 'Envio da Solicitação',
  },
] as const;

const FormLayout: FC<FormLayoutProps> = ({
  currentStep,
  children,
  ...rest
}): ReactElement => {
  const parsedOptions = options.map((option, index) => {
    if (index === currentStep) {
      return { ...option, status: BreadcrumbIconStatus.ACTIVE };
    }

    if (index < currentStep) {
      return { ...option, status: BreadcrumbIconStatus.COMPLETED };
    }

    return option;
  });

  return (
    <Route {...rest}>
      <LayoutGrid>
        <GradientAside />
        <FormContainer>
          <Breadcrumb options={parsedOptions} />
          <ErrorBoundary
            FallbackComponent={FormError}
            onError={(error, info) => {
              setContext('error_info', { info });
              captureException(error);
            }}
          >
            <Suspense fallback={<FormLoader />}>{children}</Suspense>
          </ErrorBoundary>
        </FormContainer>
      </LayoutGrid>
    </Route>
  );
};

export default FormLayout;
